import React from 'react';
import classes from './Navbar.module.scss';

const MenuItems = [
    {
        title: "Home", 
        url: "/"
    }, 
    {
        title: "About Us",
        url: "/"
    }, 
    {
        title: "Services",
        url: "/"
    }, 
    {
        title: "Contact Us",
        url: "/"
    },         
    {
        title: "Call Us: 760-241-4767",
        url: null
    },       
    

]

export default MenuItems
import classes from './AppointmentConfirmation.module.css';
import Navbar from "../../components/Navigation/Navbar";
import React, { useState } from 'react';
import emailjs, { init } from "@emailjs/browser";
import workingWithClients from '../../assets/imgs/working-with-clients.jpg';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import JoshMaggie from '../../assets/imgs/jsoh-maggie.jpg';
import joshAndClients from '../../assets/imgs/josh-and-clients.jpg';
import JoshHeadshot from '../../assets/imgs/josh-headshot-blue-bg.jpg';
import WomanAdvisorClients from '../../assets/imgs/woman-advisor-clients.jpg';
import { MdLocationOn } from 'react-icons/md';
import { BsQrCode } from 'react-icons/bs';
import { ordinal_suffix_of } from '../../shared/components/ordinalSuffix';
import { convertTime } from '../../shared/components/convertTime';
import { BiTime } from 'react-icons/bi';
import { AiOutlineSwapRight, AiTwotonePhone, AiOutlineDownload } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import StackOfPapers from '../../assets/imgs/stack-of-papers.jpg'
import rtaxteam from "../../assets/imgs/r-tax-team.jpg";
import { useEffect } from 'react';

const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

const AppointmentConfirmation = () => {

    // useEffect(() => {
    //     gtag_report_conversion();
    // }, [])

    // function gtag_report_conversion(url) {
    //     var callback = function () {
    //         if (typeof(url) != 'undefined') {
    //         window.location = url;
    //         }
    //     };
    //     gtag('event', 'conversion', {
    //         'send_to': 'AW-11290226267/VYnPCOL5oc8YENvczIcq',
    //         'event_callback': callback
    //     });
    //     return false;
    // }
    

    // (function () {
    //     emailjs.init("-NlWC3XQtQ2WM2mIsE1By");
    // })();

    const [queryParameters] = useSearchParams();

    const inviteeFullName = queryParameters.get('invitee_full_name');
    const eventStartTime = new Date(queryParameters.get('event_start_time'));
    const eventEndTime = new Date(queryParameters.get('event_end_time'));
    const eventId = queryParameters.get('invitee_uuid');
    const assignedTo = queryParameters.get('assigned_to')

    const formattedStartTime = convertTime(eventStartTime) + " " + MONTHS[eventStartTime.getMonth()] + " " + ordinal_suffix_of(eventStartTime.getDate()) + ", " + eventStartTime.getFullYear();
    const formattedEndTime = convertTime(eventEndTime) + " " + MONTHS[eventEndTime.getMonth()] + " " + ordinal_suffix_of(eventEndTime.getDate()) + ", " + eventEndTime.getFullYear();

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const updateFirstNameHandler = (e) => {
        setFirstName(e.target.value);
    }

    const updateLastNameHandler = (e) => {
        setLastName(e.target.value);
    }

    const updateEmailHandler = (e) => {
        setEmail(e.target.value);
    }

    const updatePhoneHandler = (e) => {
        setPhone(e.target.value);
    }

    const updateMessageHandler = (e) => {
        setMessage(e.target.value);
    }

    const submitForm = (e) => {
        // e.preventDefault();
        // const templateParams = {
        //     firstName: firstName,
        //     lastName: lastName,
        //     email: email,
        //     phone: phone,
        //     message: message
        // }

        // emailjs.send("service_2clswrb", "template_6ttz1ul", templateParams, "5NG10HmgPEnSFu_2C")
        //     .then(function (response) {
        //         alert("Your email was sent! We will contact you within 24 hours. Thank you for choosing Sunpath Financial.")
        //         setFirstName("");
        //         setLastName("");
        //         setEmail("");
        //         setPhone("");
        //         setMessage("");
        //         navigate("/")
        //     }, function (error) {
        //         alert("There was an error sending your email", error)
        //     });
    }
    return (
        <div className={classes.contactUs}>
            <div className="bootstrap-wrapper">
                <div className={classes.wrapNavy + ' ' + classes.fiduciaryAdvisorSection}>
                    <Navbar />
                    <div className={`container ${classes.container}`}>
                        <div className="row">
                            <div className={`column col-12 col-lg-6 ${classes.column}`} >
                                <h1 className={classes.large}>{inviteeFullName}, Your Appointment Has Been Scheduled.</h1>
                                <p style={{ marginTop: "0", fontSize: "1rem" }}>Your Scheduled Meeting Details:</p>
                                <div className={classes.meetingDetails}>
                                <div className='row'>
                                        <div className={`col-12 ${classes.assignedAdvisor}`}>
                                            <div className={classes.icon}>
                                                <BsFillPersonFill />
                                            </div>
                                            <div className={classes.intro}>Your assigned preparer is: {assignedTo}</div>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className={`col-12 ${classes.meetingDetail}`}>
                                            <div className={classes.icon}>
                                                <AiTwotonePhone size="18px"/>
                                            </div>
                                            We'll Call You on Your Scheduled Appointment Time
                                        </div>
                                    </div> */}
                                    <div className='row'>
                                        <div className={`col-12 col-lg-5 ${classes.meetingDetail}`}>
                                            <div className={classes.icon}>
                                                <BiTime size="18px"/>
                                            </div>
                                           {formattedStartTime}
                                        </div>
                                        <div className={`col-12 col-lg-1 ${classes.timeIcon}`}>
                                            <AiOutlineSwapRight />
                                        </div>
                                        <div className={`col-12 col-lg-5 ${classes.meetingDetail}`}>
                                            <div className={classes.icon}>
                                                <BiTime size="18px"/>
                                            </div>
                                           {formattedEndTime}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className={`col-12 ${classes.meetingDetail}`}>
                                            <div className={classes.icon}>
                                                <BsQrCode size="14px"/>
                                            </div>
                                            Meeting ID: {eventId}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.workingWithClients} column col-12 col-lg-6 ${classes.column}`} style={{ margin: "auto", backgroundColor: "#0b2b5c" }}>
                                <img src={rtaxteam} style={{ width: "100%" }} />
                                <p>We thank you for scheduling an appointment, and we welcome you to the Royal family. We look forward to meeting to prepare your tax return on your scheduled appointment date. </p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.wrapWht + ' ' + classes.location}>
                    <div className={`container ${classes.container}`}>
                        <div className="row">
                            <div className={`column col-12 col-lg-6 ${classes.column}`} style={{ margin: 'auto' }}>
                                <img src={StackOfPapers} />
                            </div>
                            <div className={`column col-12 col-lg-6 ${classes.column}`}>
                                <h1 className={classes.large} style={{ marginTop: "0" }}>Preparing For Your Meeting</h1>
                                <p>Please arrive promptly and be prepared to provide your preparer with any of the following items that are applicable to you:</p>
                                <p><b>Income:</b> All income related tax documents that you received including W-2s, 1099-Int statments, capital gain statements, 1099s, pensions, social security, etc.</p>
                                <p><b>Expenses:</b> Bring the total of your work related expenses, mileage log if applicable, non-cash and cash contribution receipts, mortgage statement and property tax, union dues, trade specifi write-offs, etc.</p>
                                <p><b>Business Owners:</b> Copy of previous year's tax return, 1099 or proof of income via bank statements or accounting ledgers, the itemized total of all business related expenses.</p>
                                <p><b>Students:</b> If you attended colleage, please bring your 1098-t form and proof of all other school related expenes.</p>
                                <p><b>Miscellaneous:</b> Please bring any other form related to filing your tax return.</p>
                                <p><b>*</b> If this is your first time filing with us, please bring a copy of your previous year's tax return, identification, and social security cards for all those to be included in your return.</p>
                                {/* <div className={classes.download}>
                                    <a href="https://docs.google.com/document/d/1co6JW_2CLBitY5PbSRdDSZEJjdIlEFcw2MbFZT9tJkk/edit?usp=sharing" target='_blank'>
                                        <AiOutlineDownload size="24px"/>
                                        <p>Sunpath Fact Finder Worksheet - 2023 Copyright</p>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.wrapNavy + ' ' + classes.footerSection}>
                    <div className={`container ${classes.container}`}>
                        <div className="row">
                            <p> © 2024 Royal Tax Service, Inc. and its related entities, the Royal Tax Service brand and logo are service marks of Royal Tax Service and its related entities, are registered jurisdictions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppointmentConfirmation;
import React, { useState } from "react";
import classes from "./Home.module.css";
import Navbar from "../../components/Navigation/Navbar";
import { Link } from "react-router-dom";
import rtaxteam from "../../assets/imgs/r-tax-team.jpg";
import crossIconExt from "../../assets/imgs/cross-icon-extension-navy.png";
import boating from "../../assets/imgs/boating.png";
import guarantee from "../../assets/imgs/guarantee.jpg";
import coupleRome from "../../assets/imgs/couple-rome.jpg";
import coupleCamping from "../../assets/imgs/couple-camping.jpeg";
import couplePyramids from "../../assets/imgs/couple-pyramids.jpg";
import familyDinner from "../../assets/imgs/family-dinner.jpg";
import laternFestival from "../../assets/imgs/lantern-festival.jpg";
import friendsTraveling from "../../assets/imgs/friends-traveling.jpg";
import friendsTrain from "../../assets/imgs/train-friends.jpg";
import workingWithClients from "../../assets/imgs/working-with-clients.jpg";
import testimonial from "../../assets/imgs/testimonial.jpg";
import flemmingsRanchoJosh from "../../assets/imgs/flemmings-rancho-josh.jpg";
import flemmingsRanchoJoshCloseUp from "../../assets/imgs/flemmings-rancho-josh-close-up.jpg";
import flemmingsRanchoJoshBackRoom from "../../assets/imgs/flemmings-rancho-josh-back-room-left.jpg";
import roysAnaheimTim from "../../assets/imgs/roys-anaheim-tim.jpg";
import officeNewportDisplay from "../../assets/imgs/office-newport-display.jpg";
import roysAnaheimTim2 from "../../assets/imgs/roys-anaheim-tim-2.jpg";
import flemmingsRanchoJoshCrowd from "../../assets/imgs/flemmings-rancho-josh-crowd.jpg";
import officeNewportKitchen from "../../assets/imgs/office-newport-kitchen.jpg";
import officeNewportTim from "../../assets/imgs/office-newport-tim-2.jpg";
import office1 from "../../assets/imgs/office1.jpg";
import office2 from "../../assets/imgs/office2.jpg";
import office3 from "../../assets/imgs/office3.jpg";
import office4 from "../../assets/imgs/office4.jpg";
import webinars from "../../assets/imgs/webinars.png";
import CookieConsent from "react-cookie-consent";
import EventLocationMap from "../../components/LandingPages/EventLocationMap/EventLocationMap";
import GoogleReview from "../../assets/imgs/reviewing-r-tax.jpg";
import Wow from "../../assets/imgs/wow.jpg";
import CTAArrow from "../../assets/imgs/arrow-figma.png";
import Google from "../../assets/imgs/google.png";
import Yelp from "../../assets/imgs/yelp.png";
import Facebook from "../../assets/imgs/facebook.png";
import Stars from "../../assets/imgs/stars.png";
import Dunia1 from "../../assets/imgs/dunia1.png";
import Dunia2 from "../../assets/imgs/dunia2.png";
import Dunia3 from "../../assets/imgs/dunia3.png";
import Dunia4 from "../../assets/imgs/dunia4.png";
import Dunia5 from "../../assets/imgs/dunia5.jpg";
import Dunia6 from "../../assets/imgs/dunia6.jpg";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoCloseCircleSharp } from "react-icons/io5";
import Stephanie from "../../assets/imgs/stephanie.jpg";
import Ivone from "../../assets/imgs/ivone.jpg";
import Alondra from "../../assets/imgs/alondra.jpg";

const Home = () => {
  const [worthLivingImgActive, setWorthLivingImgActive] = useState(boating);
  const [eventsImgActive, setEventsImgActive] = useState(
    flemmingsRanchoJoshCloseUp
  );
  const [appointmentMenuIsOpen, setAppointmentMenuIsOpen] = useState(false);

  const updateWorthLivingImgHandler = (event) => {
    setWorthLivingImgActive(event.target.dataset.url);
  };

  const updateEventsImgHandler = (event) => {
    setEventsImgActive(event.target.dataset.url);
  };

  const toggleAppointmentMenu = () => {
    setAppointmentMenuIsOpen((prevState) => !prevState);
  };

  return (
    <div className={classes.home}>
      <div className="bootstrap-wrapper">
        <div className={`${classes.wrapNavy} ${classes.mainSection}`}>
          <Navbar />
          <div className={`container ${classes.container}`}>
            <div className="row">
              <div
                className={`column col-12 col-lg-6 ${classes.column} ${classes.left}`}
              >
                <div>50-Years as a Family Owned and Operated Business</div>
                <h1 className={classes.large}>
                  Tax Preparation & Accounting for Individuals & Businesses
                </h1>
                <div className="row">
                  {/* <div className="col-2 hidden-md-down" style={{ lineHeight: "0rem", marginTop: ".25rem" }}>________</div> */}
                  <div className="col-lg-12" style={{ position: "relative" }}>
                    <p style={{ marginTop: "0", fontSize: "1rem" }}>
                      We're open and accepting appointments to pepare 2023 tax
                      returns. To book an appointment you can call us, or click
                      the button below and select the best day and time.
                    </p>
                    {!appointmentMenuIsOpen && (
                      <>
                        <button
                          className="redWhtRedBtn col-lg-12"
                          onClick={toggleAppointmentMenu}
                        >
                          CLICK HERE TO BOOK
                        </button>
                        <div className={classes.reviews}>
                          <div>
                            <img src={Google} />
                          </div>
                          <div>
                            <img src={Yelp} />
                          </div>
                          <div>
                            <img src={Facebook} />
                          </div>
                        </div>
                      </>
                    )}
                    {appointmentMenuIsOpen && (
                      <div className={classes.preparerMenu}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            borderBottom: "1px solid lightgray"
                          }}
                        >
                          <div
                            style={{
                              padding: ".5rem",
                              flex: "2",
                              fontWeight: "bold"
                            }}
                          >
                            Choose Your Tax Preparer:
                          </div>
                          <div onClick={toggleAppointmentMenu}>
                            <IoCloseCircleSharp size="1.5rem" />
                          </div>
                        </div>
                        <ul>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-stephanie/30min";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Stephanie} />
                                Stephanie Crowe
                              </div>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-ivone/tax-preparation-with-ivone-arias";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Ivone} />
                                Ivone Arias
                              </div>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-alondra/30min";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Alondra} />
                                Alodra Felix
                              </div>
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                    {!appointmentMenuIsOpen && (
                      <div className={`${classes.ctaArrow} hidden-md-down`}>
                        <img src={CTAArrow} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`column col-12 col-lg-6 ${classes.column} ${classes.mainImg}`}
              >
                <img src={rtaxteam} />
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            classes.wrapWht + " " + classes.retirementWorthLivingSection
          }
        >
          <div className={`container ${classes.container}`}>
            <div className="row">
              <div
                className={`column ${classes.column} col-12 col-lg-6 order-lg-2`}
              >
                <p style={{ margin: "0", color: "rgb(105, 105, 105)" }}>
                  Introducing the Royal Tax Guarantee - Our Best Offer Yet!
                </p>
                <h1 className={classes.large}>
                  Guaranteed Biggest Refund or We'll Prepare Your Taxes for
                  Free!
                </h1>
                <div style={{ position: "relative" }}>
                  <p style={{ margin: "0", color: "rgb(105, 105, 105)" }}>
                    We're so confident that we'll beat your tax preparer's,
                    account's, or CPA's bottom line, that we'll preparer your
                    taxes free of charge if we cannot.
                  </p>
                  <p style={{ color: "rgb(105, 105, 105)" }}>
                    You can get started with our guarantee by booking an
                    appointment with one of our licensed tax professionals.
                    Appointments can be booked by calling us at 760.241.4767 or
                    by clicking the button below.
                  </p>
                  <p style={{ color: "rgb(105, 105, 105)" }}>
                    During the meeting, your preparer will use their expertise
                    to reduce your tax liability and maximize your refund!
                  </p>
                </div>
                {/* <div className={`row ${classes['worthLivingImages']}`} style={{ display: "flex" }}>
                  <div className="col-4" onClick={updateWorthLivingImgHandler} data-url={guarantee}>
                    <img src={guarantee} data-url={guarantee} />
                  </div>
                  <div className="col-4" onClick={updateWorthLivingImgHandler} data-url={coupleCamping}>
                    <img src={coupleCamping} data-url={coupleCamping} />
                  </div>
                  <div className="col-4" onClick={updateWorthLivingImgHandler} data-url={coupleRome}>
                    <img src={coupleRome} data-url={coupleRome} />
                  </div>
                  <div className="col-4" onClick={updateWorthLivingImgHandler} data-url={couplePyramids}>
                    <img src={couplePyramids} data-url={couplePyramids} />
                  </div>
                  <div className="col-4" onClick={updateWorthLivingImgHandler} data-url={friendsTraveling}>
                    <img src={friendsTraveling} data-url={friendsTraveling} />
                  </div>
                  <div className="col-4" onClick={updateWorthLivingImgHandler} data-url={friendsTrain}>
                    <img src={friendsTrain} data-url={friendsTrain} />
                  </div>
                </div> */}
                <div
                  className="row"
                  style={{ padding: "0", marginTop: "1.5rem" }}
                >
                  <div className="col-7 col-12">
                    {!appointmentMenuIsOpen && (
                      <button
                        className="redWhtRedBtn col-12"
                        onClick={toggleAppointmentMenu}
                      >
                        CLICK HERE TO BOOK
                      </button>
                    )}
                    {appointmentMenuIsOpen && (
                      <div className={classes.preparerMenu}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            borderBottom: "1px solid lightgray"
                          }}
                        >
                          <div
                            style={{
                              padding: ".5rem",
                              flex: "2",
                              fontWeight: "bold"
                            }}
                          >
                            Choose Your Tax Preparer:
                          </div>
                          <div onClick={toggleAppointmentMenu}>
                            <IoCloseCircleSharp size="1.5rem" />
                          </div>
                        </div>
                        <ul>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-stephanie/30min";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Stephanie} />
                                Stephanie Crowe
                              </div>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-ivone/tax-preparation-with-ivone-arias";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Ivone} />
                                Ivone Arias
                              </div>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-alondra/30min";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Alondra} />
                                Alodra Felix
                              </div>
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  {/* <div className="col-5">
                      <button className="blkBlkWhtBtn">LEARN MORE</button>
                    </div> */}
                </div>
              </div>
              <div className={`column col-12 col-lg-6 ${classes.column}`}>
                <div className={classes.worthLivingMainImg}>
                  <img src={guarantee}></img>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4 hidden-md-down">
                    {/* <img src={crossIcon} className={classes.crossIcon}></img> */}
                  </div>
                  <div
                    className="col-12 col-lg-8 order-lg-1"
                    style={{
                      fontStyle: "italic",
                      borderLeft: "5px solid lightgray"
                    }}
                  >
                    <p style={{ color: "rgb(105, 105, 105)" }}>
                      "I worked a lot last year and paid the fed and state more
                      than I ever had. I knew I was going to need an expert.
                    </p>
                    <p style={{ color: "rgb(105, 105, 105)" }}>
                      While looking up tax offices on google, I stumbled across
                      one of the highest ranked tax preparation office, Royal
                      Tax, and figured I'd give them a try.
                    </p>
                    <p style={{ color: "rgb(105, 105, 105)" }}>
                      I am happy I did, my last tax preparer had me owing,
                      whereas Royal Tax was able to get a refund. Beyond
                      thankful for their services.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classes.wrapNavy + " " + classes.fiduciaryAdvisorSection}
        >
          <div className={`container ${classes.container}`}>
            <div className="row">
              <div className={`column col-12 col-lg-6 ${classes.column}`}>
                <h1 className={classes.large}>
                  Get an Additional 10% Off this Year
                </h1>
                <p style={{ marginTop: "0", color: "white", fontSize: "1rem" }}>
                  Review Our Company and Snap a Photo with Your Preparer
                </p>
                <p>
                  If you help us, we'll help you - that's the Royal Tax way. All
                  you have to do is prove that you've reviewed our company with
                  your Tax Preparer, and Voila, 5% off!
                </p>
                <p>
                  You can earn an additional 5% for snapping a photo with your
                  preparer, and you might even go viral with us on facebook or
                  instagram.
                </p>
                <div className="row" style={{ padding: "0" }}>
                  <div className="col-7 col-12">
                    {!appointmentMenuIsOpen && (
                      <button
                        className="redWhtRedBtn col-12"
                        onClick={toggleAppointmentMenu}
                      >
                        CLICK HERE TO BOOK
                      </button>
                    )}
                    {appointmentMenuIsOpen && (
                      <div className={classes.preparerMenu}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            borderBottom: "1px solid lightgray"
                          }}
                        >
                          <div
                            style={{
                              padding: ".5rem",
                              flex: "2",
                              fontWeight: "bold"
                            }}
                          >
                            Choose Your Tax Preparer:
                          </div>
                          <div onClick={toggleAppointmentMenu}>
                            <IoCloseCircleSharp size="1.5rem" />
                          </div>
                        </div>
                        <ul>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-stephanie/30min";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Stephanie} />
                                Stephanie Crowe
                              </div>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-ivone/tax-preparation-with-ivone-arias";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Ivone} />
                                Ivone Arias
                              </div>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-alondra/30min";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Alondra} />
                                Alodra Felix
                              </div>
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  {/* <div className="col-5">
                        <button className="blkBlkWhtBtn">LEARN MORE</button>
                      </div> */}
                </div>
              </div>
              <div
                className={`${classes.workingWithClients} column ${classes.column} col-12 col-lg-6`}
              >
                <img src={GoogleReview} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.wrapWht + " " + classes.ourServicesSection}>
          <div className={`container ${classes.container}`}>
            <div className="row">
              <div
                className={`column col-12 col-lg-7 order-lg-2 ${classes.column} ${classes.textRight}`}
                style={{ zIndex: "4" }}
              >
                <div className={classes.textRight}>
                  <p style={{ color: "rgb(105, 105, 105)" }}>
                    We're a Full-Service Tax and Accounting Firm at Your Service
                  </p>
                  <h1 className={classes.large}>
                    Business or Individual? Royal Tax Has You Covered!
                  </h1>
                  <div style={{ position: "relative" }}>
                    <p style={{ color: "rgb(105, 105, 105)" }}>
                      As a full-service tax and accounting firm, we provide a
                      suite of services for individuals and business owners at a
                      fair rate, so there's no reason to look up those new tax
                      codes.
                    </p>
                    {/* <p>It works by using a math equation to figure how much you’d need to save each month to reach your retirement income shortfall, and divides it by how much you’re actually contributing.</p> */}
                    <p style={{ color: "rgb(105, 105, 105)" }}>
                      Let Royal Tax's licensed preparers and accountants do all
                      the heavy lifting, by helping you prepare and manage all
                      your tax needs, so you can get back to your vocation.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {!appointmentMenuIsOpen && (
                      <button
                        className="redWhtRedBtn col-12"
                        onClick={toggleAppointmentMenu}
                      >
                        CLICK HERE TO BOOK
                      </button>
                    )}
                    {appointmentMenuIsOpen && (
                      <div className={classes.preparerMenu}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            borderBottom: "1px solid lightgray"
                          }}
                        >
                          <div
                            style={{
                              padding: ".5rem",
                              flex: "2",
                              fontWeight: "bold"
                            }}
                          >
                            Choose Your Tax Preparer:
                          </div>
                          <div onClick={toggleAppointmentMenu}>
                            <IoCloseCircleSharp size="1.5rem" />
                          </div>
                        </div>
                        <ul>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-stephanie/30min";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Stephanie} />
                                Stephanie Crowe
                              </div>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-ivone/tax-preparation-with-ivone-arias";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Ivone} />
                                Ivone Arias
                              </div>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-alondra/30min";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Alondra} />
                                Alodra Felix
                              </div>
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`column col-12 col-lg-5 order-lg-1 ${classes.column} ${classes.serviceList}`}
                style={{ zIndex: "4" }}
              >
                <div className={`${classes.ourServicesFeatures}`}>
                  <h2>Individual Tax Preparation</h2>
                  {/* <img style={{width: '100%'}}></img> */}
                  {/* <p>The process for filing and collecting your social security and pension benefits is confusing yet critical, and your decision is irreversible. Our income planning process ensures you get the right education to make the absolute best decision.</p> */}
                  <p>
                    Our professional, trained, and licensed preparers will ask
                    all the pertinent questions so that you can take full
                    advantage of your write-offs.
                  </p>
                </div>
                <div className={`${classes.ourServicesFeatures}`}>
                  <h2>Corporate Tax Preparation</h2>
                  {/* <img  style={{width: '100%'}}></img> */}
                  <p>
                    Our team can help setup and prepare your business tax returs
                    including LLC, S-Corp, C-Corp, and provide ongoing
                    consultation to maizmize outcome.
                  </p>
                </div>
                <div className={`${classes.ourServicesFeatures}`}>
                  <h2>Accounting and Bookkeeping</h2>
                  {/* <img  style={{width: "100%"}}></img> */}
                  <p>
                    Prioritzie your business operations by delegating your
                    accounting and bookkeeping to a Royal Tax accounting
                    specialist. We've got 50 years of experience handling books.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.wrapNavy + " " + classes.testimonialSection}>
          <div className={`container ${classes.container}`}>
            <div className="row">
              <div className={`column col-12 col-lg-6 ${classes.column}`}>
                <h1 className={classes.large}>5-Star Google & Yelp Ratings</h1>
                <div className={classes.reviews}>
                  <div>
                    <img src={Google} />
                  </div>
                  <div>
                    <img src={Yelp} />
                  </div>
                  <div>
                    <img src={Facebook} />
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <p>
                    We work hard to provide our clients with an unparalleled,
                    unbeatable, 5-star service.
                  </p>
                  <p>
                    The work is challenging, but the compliments we receive from
                    our clientele helps us to persevere.
                  </p>
                  <p>
                    Here are some of the things our clientele has said about us
                    over the past 50 years.
                  </p>
                  <div className="row" style={{ padding: "0" }}>
                    <div className="col-12">
                      {!appointmentMenuIsOpen && (
                        <button
                          className="redWhtRedBtn col-12"
                          onClick={toggleAppointmentMenu}
                        >
                          CLICK HERE TO BOOK
                        </button>
                      )}
                      {appointmentMenuIsOpen && (
                        <div className={classes.preparerMenu}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                              borderBottom: "1px solid lightgray"
                            }}
                          >
                            <div
                              style={{
                                padding: ".5rem",
                                flex: "2",
                                fontWeight: "bold"
                              }}
                            >
                              Choose Your Tax Preparer:
                            </div>
                            <div onClick={toggleAppointmentMenu}>
                              <IoCloseCircleSharp size="1.5rem" />
                            </div>
                          </div>
                          <ul>
                            <li>
                              <button
                                onClick={() => {
                                  window.location.href =
                                    "https://calendly.com/royal-tax-service-stephanie/30min";
                                  return null;
                                }}
                              >
                                <div className={classes.preparerMini}>
                                  <img src={Stephanie} />
                                  Stephanie Crowe
                                </div>
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => {
                                  window.location.href =
                                    "https://calendly.com/royal-tax-service-ivone/tax-preparation-with-ivone-arias";
                                  return null;
                                }}
                              >
                                <div className={classes.preparerMini}>
                                  <img src={Ivone} />
                                  Ivone Arias
                                </div>
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => {
                                  window.location.href =
                                    "https://calendly.com/royal-tax-service-alondra/30min";
                                  return null;
                                }}
                              >
                                <div className={classes.preparerMini}>
                                  <img src={Alondra} />
                                  Alodra Felix
                                </div>
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`column col-12 col-lg-6 ${classes.column}`}
                style={{
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem"
                }}
              >
                <div
                  style={{
                    border: "1px solid lightgray",
                    padding: "1rem",
                    borderRadius: ".25rem"
                  }}
                >
                  <div>
                    <h4>Ronald Ramirez</h4>
                    <img src={Stars} style={{ width: "7rem" }} />
                    <p>
                      Great service there and nice and friendly. You do your
                      taxes they're not too expensive there. There are enough
                      parking place here. Not too hard to find. The place is
                      clean. Neighborhood is good.👍
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid lightgray",
                    padding: "1rem",
                    borderRadius: ".25rem"
                  }}
                >
                  <div>
                    <h4>Andrea L.</h4>
                    <img src={Stars} style={{ width: "7rem" }} />
                    <p>
                      I’ve been going here for a few years now. By far the best
                      place I’ve been to for my taxes. Amazing customer service,
                      highly recommended👍🏽.
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid lightgray",
                    padding: "1rem",
                    borderRadius: ".25rem"
                  }}
                >
                  <div>
                    <h4>Carlos Campos</h4>
                    <img src={Stars} style={{ width: "7rem" }} />
                    <p>
                      Been going to them to get my taxes done for years. They’re
                      a family business with great people. Definitely recommend.
                    </p>
                  </div>
                </div>
                <p className={classes.seeMore}>
                  <a href="https://www.google.com/search?q=royal+tax+service+victorville&oq=royal+&gs_lcrp=EgZjaHJvbWUqCAgAEEUYJxg7MggIABBFGCcYOzIGCAEQRRhAMgYIAhBFGDkyBggDEEUYOzIMCAQQIxgnGIAEGIoFMgYIBRBFGD0yBggGEEUYPTIGCAcQRRg9qAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x80c368a07d469c59:0x88eaf7bb38c29a53,1,,,,">
                    Click here to see more...
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.wrapWht + " " + classes.location}>
          <div className={`container ${classes.container}`}>
            <div className="row">
              <div className={`column col-12 col-lg-6 ${classes.column}`}>
                <div className="row">
                  <div className="col-6">
                    <img src={Dunia1} />
                  </div>
                  <div className="col-6">
                    <img src={Dunia6} />
                  </div>
                </div>
                <div className="row" style={{ margin: "1rem 0" }}>
                  <EventLocationMap
                    mapCoordinates={{
                      center: { lat: 34.46916, lng: -117.35452 },
                      zoom: 14
                    }}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <img src={Dunia3} />
                  </div>
                  <div className="col-6">
                    <img src={Dunia4} />
                  </div>
                </div>
              </div>
              <div className={`column col-12 col-lg-6 ${classes.column}`}>
                <h1 className={classes.large}>
                  Located in the Beautiful Dunia Plaza in Victorville
                </h1>
                <p style={{ color: "rgb(105, 105, 105)" }}>
                  Neslted in the middle of the Dunia strip mall, our decadent
                  location is only one mile from the I-15 highway and offers
                  ample parking. The plaza is a well kept and one of the safest
                  in the High Desert.
                </p>
                <p
                  style={{
                    borderLeft: "1px solid rgb(105, 105, 105)",
                    paddingLeft: "1rem"
                  }}
                >
                  <span style={{ fontWeight: "bold", color: "#333333" }}>
                    Royal Tax Service, Inc.
                  </span>
                  <span
                    className="paragraphBreak"
                    style={{ color: "rgb(105, 105, 105)" }}
                  >
                    100 Bayview Circle, Suite 100
                  </span>
                  <span
                    className="paragraphBreak"
                    style={{ color: "rgb(105, 105, 105)" }}
                  >
                    Newport Beach, Ca 92660
                  </span>
                </p>
                {/* <div className="row" style={{padding: "1.5rem 0rem"}}>
                  <div className="col-6">
                   <img src={office1} style={{width: "100%"}}></img>
                  </div>
                  <div className="col-6">
                    <img src={office2} style={{width: "100%"}}></img>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-12">
                    {!appointmentMenuIsOpen && (
                      <button
                        className="redWhtRedBtn col-12"
                        onClick={toggleAppointmentMenu}
                      >
                        CLICK HERE TO BOOK
                      </button>
                    )}
                    {appointmentMenuIsOpen && (
                      <div className={classes.preparerMenu}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            borderBottom: "1px solid lightgray"
                          }}
                        >
                          <div
                            style={{
                              padding: ".5rem",
                              flex: "2",
                              fontWeight: "bold"
                            }}
                          >
                            Choose Your Tax Preparer:
                          </div>
                          <div onClick={toggleAppointmentMenu}>
                            <IoCloseCircleSharp size="1.5rem" />
                          </div>
                        </div>
                        <ul>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-stephanie/30min";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Stephanie} />
                                Stephanie Crowe
                              </div>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-ivone/tax-preparation-with-ivone-arias";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Ivone} />
                                Ivone Arias
                              </div>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                window.location.href =
                                  "https://calendly.com/royal-tax-service-alondra/30min";
                                return null;
                              }}
                            >
                              <div className={classes.preparerMini}>
                                <img src={Alondra} />
                                Alodra Felix
                              </div>
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.wrapNavy + " " + classes.footerSection}>
          <div className={`container ${classes.container}`}>
            <div className="row">
              <p>
                {" "}
                © 2024 Royal Tax Service, Inc. and its related entities, the
                Royal Tax Service brand and logo are service marks of Royal Tax
                Service and its related entities, are registered jurisdictions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <CookieConsent style={{ fontSize: ".8rem" }}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
};

export default Home;
